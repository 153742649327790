import { useState } from "react";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getMongoDb } from "../../../utils/query";
import {
  Collection,
  HospitalPaymentsExaminationID,
  PetStatusID,
} from "../../../constants/common";
import { checkActionErr } from "../../../contexts/CustomErrorBoundary";
import {
  openUpdateActionLoading,
  closeActionLoading,
} from "../../base/useLoadingAction";
import useForceUpdate from "../../common/useForceUpdate";
import useNlc028Conditions from "./useNlc028Conditions";
import { closeNlc028Dialog } from "./useNlc028Dialog";
import { closeNlc028CancelDialog } from "./useNlc028CancelDialog";
import generateUniqueId from "../../../utils/generateUniqueId";

const useNlc028DbActions = () => {
  interface Modality {
    _id: string;
    hospital_id: string;
  }
  interface Hospital {
    _id: string;
    service_price: {
      mri: number;
      pet: number;
    };
  }
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [actionError, setActionError] = useState<Error | null>(null);

  const [{ modalityId, interpretationNote }] = useNlc028Conditions();
  const [forceUpdate, setForceUpdate] = useForceUpdate();

  // PET 読影登録、ステータスを診断済
  const confirmSelection = () => {
    void (async () => {
      try {
        openUpdateActionLoading();
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        await collection.updateOne(
          { _id: modalityId },
          {
            $set: {
              pet_status: PetStatusID.DIAGNOSED,
              interpretation_note: interpretationNote,
              updated_at: new Date(),
            },
          },
        );
        setForceUpdate({ forceUpdateCount: forceUpdate.forceUpdateCount + 1 });
        closeNlc028Dialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  // (PET)結果送信取消
  const sendCancel = (patientId: string) => {
    void (async () => {
      openUpdateActionLoading();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_MODALITY_BOOKS,
        );
        const updateData = {
          pet_status: PetStatusID.DIAGNOSED,
          updated_at: new Date(),
        };

        await collection.updateOne({ _id: modalityId }, { $set: updateData });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const modality: Modality | null = await collection.findOne({
          _id: modalityId,
        });
        if (!modality) {
          throw new Error("Modality not found");
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const hospital: Hospital | null = await getMongoDb(
          currentUser,
          Collection.HOSPITALS,
        ).findOne({ _id: modality?.hospital_id });
        if (!hospital) {
          throw new Error("Hospital not found");
        }

        const uniqueId = generateUniqueId();
        // 検査費用支払いデータ登録
        const insertData = {
          _id: uniqueId,
          patient_id: patientId,
          hospital_id: modality.hospital_id,
          examination: HospitalPaymentsExaminationID.PET,
          amount: -hospital.service_price.pet,
          exam_date: new Date(),
          created_at: new Date(),
          updated_at: new Date(),
        };

        await getMongoDb(currentUser, Collection.HOSPITAL_PAYMENTS).insertOne(
          insertData,
        );
        setForceUpdate({
          forceUpdateCount: forceUpdate.forceUpdateCount + 1,
        });
        closeNlc028Dialog();
        closeNlc028CancelDialog();
      } catch (err) {
        setActionError(checkActionErr(err));
      } finally {
        closeActionLoading();
      }
    })();
  };

  return { actionError, confirmSelection, sendCancel };
};

export default useNlc028DbActions;
