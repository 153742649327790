import React from "react";

import DialogCustom from "../components/Dialog/DialogCustom";
import Button from "../components/Button/Button";
import Input from "../components/Input/Input";
import LayoutBox from "../components/LayoutBox/LayoutBox";
import FormSet from "../components/FormSet/FormSet";
import Dropdown from "../components/Dropdown/Dropdown";
import RadioButtonGroup from "../components/RadioButton/RadioButtonGroup";
import useUserDetailInput, {
  checkUserDetailInputError,
  setEmail,
  setRole,
  setStatus,
  setUserName,
} from "../hooks/input/useUserDetailInput";
import useNli004DbActions from "../hooks/pages/NLI004/useNli004DbActions";
import { useCheckErrorThrowError } from "../utils/checkError";
import InformationDialog from "../components/Dialog/InformationDialog";
import { ADMIN_SELECT_ROLE, ADMIN_SELECT_STATUS } from "../constants/common";
import usePasswordInput, {
  checkPasswordInputError,
  setPassword,
  setPasswordError,
  setRePassword,
} from "../hooks/input/usePasswordInput";
import { useRealmApp } from "../contexts/RealmApp";
import useNli003Fetch from "../hooks/pages/NLI003/useNli003Fetch";

type NLI004Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  // eslint-disable-next-line react/require-default-props
  user?: Nli003TableType | null;
  // eslint-disable-next-line react/require-default-props
  setUser?: React.Dispatch<React.SetStateAction<Nli003TableType | undefined>>;
};

const NLI004: React.FC<NLI004Props> = ({
  isOpen,
  setIsOpen,
  title,
  user = null,
  setUser = null,
}) => {
  const realmAppContext = useRealmApp();
  const [id, setId] = React.useState("");
  const [{ userName, email, role, status }] = useUserDetailInput();
  const [{ password, rePassword }] = usePasswordInput();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false);
  const [isInterviewDialogOpen, setIsInterviewDialogOpen] =
    React.useState(false);
  const {
    actionError,
    handleRegisterUser,
    handleUpdateUser,
    handleDeleteUser,
    existingEmailError,
  } = useNli004DbActions();
  const { fetchInterviewResult, error: interviewError } = useNli003Fetch(id);

  useCheckErrorThrowError([actionError, existingEmailError, interviewError]);

  React.useEffect(() => {
    if (user) {
      setId(user.id);
      setUserName(user.fullName);
      setEmail(user.email);
      const selectRole = ADMIN_SELECT_ROLE.find(
        (option) => option.label === user.roleStr,
      );
      if (selectRole) {
        setRole(selectRole.value);
      }
      setStatus(user.statusStr);
    }
  }, [user]);

  // スタータス
  const handleChangeRadio = React.useCallback((value: string) => {
    setStatus(value);
  }, []);

  // 登録ボタン押下
  const handleRegisterUserButton = () => {
    if (user) {
      if (role === "2" && status === "0") {
        setIsInterviewDialogOpen(fetchInterviewResult.length > 0);
      } else {
        // 更新
        handleUpdateUser(id, userName.value, role, status, () =>
          setIsOpen(false),
        );
      }
    } else {
      // パスワードバリデーション
      if (password.value !== rePassword.value) {
        setPasswordError();

        return;
      }
      // 登録
      handleRegisterUser(
        userName.value,
        email.value,
        role,
        password.value,
        () => setIsOpen(false),
      );
    }
  };

  // 削除ダイアログclose
  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  // 削除ダイアログopen
  const handleDeleteConfirm = () => {
    setIsDeleteConfirmOpen(true);
  };

  // 更新ダイアログclose
  const handleCloseUpdateConfirm = () => {
    setIsInterviewDialogOpen(false);
  };

  // 更新処理
  const handleUpdate = () => {
    handleUpdateUser(id, userName.value, role, status, () => setIsOpen(false));
    setIsInterviewDialogOpen(false);
    setIsOpen(false);
  };

  // 削除処理
  const handleDelete = (id?: string) => {
    if (id) {
      handleDeleteUser(id);
    }
    setIsDeleteConfirmOpen(false);
    setIsOpen(false);
  };

  // 閉じる、キャンセルボタン押下
  const handleCloseButton = () => {
    if (setUser) {
      setUser(undefined);
    }
    setIsOpen(false);
  };

  return (
    <>
      <DialogCustom
        title={title}
        size="medium"
        height="662px"
        open={isOpen}
        closeDialog={handleCloseButton}
        footerLeft={
          user && realmAppContext.currentUser?.id !== user.id ? (
            <Button
              type="secondary"
              color="danger"
              icon="delete"
              size="large"
              onClick={handleDeleteConfirm}
            >
              削除
            </Button>
          ) : undefined
        }
        footerRight={
          <LayoutBox>
            <Button
              onClick={handleCloseButton}
              color="neutral"
              type="sub"
              size="large"
            >
              キャンセル
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={handleRegisterUserButton}
              disabled={
                user
                  ? checkUserDetailInputError()
                  : checkUserDetailInputError() || checkPasswordInputError()
              }
            >
              登録
            </Button>
          </LayoutBox>
        }
      >
        <LayoutBox direction="column" fullWidth>
          <FormSet
            label="ユーザー名"
            vertical
            required="icon"
            errorTextList={[userName.validationMessage]}
          >
            <Input
              size="default"
              value={userName.value}
              placeholder="ユーザー名"
              width="100%"
              onChange={(value) => setUserName(value)}
              error={userName.isError}
            />
          </FormSet>

          <FormSet
            label="メールアドレス"
            vertical
            required="icon"
            errorTextList={[email.validationMessage]}
          >
            <Input
              disabled={!!user}
              size="default"
              value={email.value}
              placeholder="メールアドレス"
              width="100%"
              onChange={(value) => setEmail(value)}
              error={email.isError}
            />
          </FormSet>
          <FormSet label="権限" vertical required="icon">
            <Dropdown
              value={role}
              width="152px"
              onChange={(value) => setRole(value)}
              size="default"
              status="default"
              items={ADMIN_SELECT_ROLE}
            />
          </FormSet>

          <FormSet label="ステータス" vertical required="icon">
            <LayoutBox>
              <RadioButtonGroup
                vertical
                column={6}
                name="radio"
                items={ADMIN_SELECT_STATUS}
                selectedValue={status}
                onChange={handleChangeRadio}
              />
            </LayoutBox>
          </FormSet>
          {!user && (
            <>
              <FormSet
                label="新しいパスワード"
                vertical
                required="icon"
                errorTextList={[password.validationMessage]}
              >
                <Input
                  size="default"
                  width="259px"
                  password
                  rightButton="password"
                  autoComplete="new-password"
                  value={password.value}
                  onChange={(value) => setPassword(value)}
                  error={password.isError}
                />
              </FormSet>
              <FormSet
                label="新しいパスワード（確認用）"
                vertical
                required="icon"
                errorTextList={[rePassword.validationMessage]}
              >
                <Input
                  size="default"
                  width="259px"
                  password
                  rightButton="password"
                  autoComplete="new-password"
                  value={rePassword.value}
                  onChange={(value) => setRePassword(value)}
                  error={rePassword.isError}
                />
              </FormSet>
            </>
          )}
        </LayoutBox>
        {isDeleteConfirmOpen && (
          <InformationDialog
            alertLevel="error"
            open={isDeleteConfirmOpen}
            closeDialog={handleCloseDeleteConfirm}
            title="削除してもよろしいですか？"
            footer={
              <LayoutBox>
                <Button
                  color="danger"
                  size="medium"
                  onClick={() => handleDelete(user?.id)}
                >
                  削除
                </Button>
                <Button
                  color="neutral"
                  type="sub"
                  size="medium"
                  onClick={handleCloseDeleteConfirm}
                >
                  キャンセル
                </Button>
              </LayoutBox>
            }
          >
            この操作は取り消せません。
          </InformationDialog>
        )}
        {isInterviewDialogOpen && (
          <InformationDialog
            alertLevel="error"
            open={isInterviewDialogOpen}
            closeDialog={handleCloseUpdateConfirm}
            title="ステータスを停止してもよろしいですか？"
            footer={
              <LayoutBox>
                <Button
                  color="danger"
                  size="medium"
                  onClick={() => handleUpdate()}
                >
                  停止
                </Button>
                <Button
                  color="neutral"
                  type="sub"
                  size="medium"
                  onClick={handleCloseUpdateConfirm}
                >
                  キャンセル
                </Button>
              </LayoutBox>
            }
          >
            オンライン面談の未来日予約が入っています。
            <br />
            ドクターユーザーを停止ステータスにした場合、発行済みのZoomリンクは引き続き利用できますが、担当者の調整を行ってください。
          </InformationDialog>
        )}
      </DialogCustom>
    </>
  );
};

export default NLI004;
