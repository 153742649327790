import { useState, useEffect } from "react";
import { Collection } from "../../../constants/common";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";
import { useRealmApp } from "../../../contexts/RealmApp";
import { getAggregateNli003Interview, getMongoDb } from "../../../utils/query";
import { addLoadCount, decrementLoadCount } from "../../base/useLoadingPage";
import useStateCustomObj from "../../base/useStateCustomObj";

// Pet受診可能な病院を都道府県で取得
const useNli003Fetch = (id: string) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  const [fetchInterviewResult, setFetchInterviewResult] = useStateCustomObj<
    Nli003InterviewStateType[]
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      addLoadCount();
      try {
        const collection = getMongoDb(
          currentUser,
          Collection.PATIENT_INTERVIEWS,
        );
        const aggregate = getAggregateNli003Interview(id);
        const fetchResult = (await collection.aggregate(
          aggregate,
        )) as Nli003InterviewStateType[];

        setFetchInterviewResult(fetchResult);
      } catch (err) {
        setError(checkFetchErr(err));
      } finally {
        decrementLoadCount();
      }
    };

    if (id) void fetchData();
  }, [currentUser, id, setFetchInterviewResult]);

  return { error, fetchInterviewResult };
};

export default useNli003Fetch;
